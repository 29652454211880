import { useCallback, useMemo } from 'react';

export function useCombinedState(initialState = {}, applicators = {}) {
  const applyChanges = useCallback(
    (changes = {}) => ({ ...initialState, ...changes }),
    [initialState],
  );

  return useMemo(() => Object.keys(applicators)
    .reduce((functions, key) => {
      const func = applicators[key];
      if (typeof func === 'function') {
        return {
          ...functions,
          [key]: (...args) => {
            const value = func(...args);
            return applyChanges(value);
          },
        };
      }

      return {
        ...functions,
        [key]: () => applyChanges(func),
      };
    }, {}), [applicators, applyChanges]);
}

import { gql } from 'apollo-boost';

const LEAD_GEN_UPDATE = gql`
    mutation clientUpdate($client_id: Int!, $payload: ClientUpdateInput!){
        updateClient(client_id: $client_id, payload: $payload) {
            id
            voter_details
        }
    }
`;

export default LEAD_GEN_UPDATE;

import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Button as AntButton, Col, Icon, Collapse, Form, Input as AntInput, message, Popover, Row, Select, Switch } from 'antd';
import Textarea from 'react-textarea-autosize';
import QR from 'qrcode';
import styled from 'styled-components/macro';
import AutosaveStatus from '../AutosaveStatus/AutosaveStatus';

import { defaultsTo, getEventDomain } from '../../helpers';
import useAutoSavingForm from '../../hooks/useAutoSavingForm';

import QRCode from '../Presenter/QRCode';

import { BlockRight, CLink, CLinkButton, PageHeader } from './EventForm.styled';
import constants from '../../constants';
import LocalizedTooltipBubble, { TooltipContentWrapper } from '../common/TooltipBubble/TooltipBubble';
import ImageUploadButton from '../ImageUpload/ImageUploadButton';

import PageControls from '../common/PageControls/PageControls';
import PageHeading from '../common/PageHeading/PageHeading';
import usePresenterUrl from '../../hooks/presenter/usePresenterUrl';
import { useMutation } from '@apollo/react-hooks';
import LEAD_GEN_UPDATE from '../../graphql/mutations/leadGenUpdate';

const Input = styled(AntInput)`
  margin-bottom: 8px;

  .ant-input-group-addon {
    padding: 0;
  }
`;
const Label = styled.span`
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
`;

const RightSpan = styled.div`
  padding: 0 8px 8px;
`;

const StyledItem = styled(Form.Item)`
  margin: 0;
  position: relative;

  label {
    display: flex;
    align-items: center;

    i.anticon {
      margin: 0 8px !important;
    }
  }

  label::after {
    display: none;
    height: 0;
  }
`;

const AddonButton = styled(AntButton)`
border: none;
border-bottom-left-radius: 0;
border-top-left-radius: 0;
padding: 0 20px;
height: 30px;
`;

const EventForm = (props) => {
  const { type, isModerator } = props;
  const { form, onSubmit, event = {}, eventCreated, error, setFormChanged, clientModerators } = props;
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const { Option } = Select;
  const { t } = useTranslation();
  const presenterUrl = usePresenterUrl();

  const primaryColor = defaultsTo(event, 'primary_color', '1B1464');
  const secondaryColor = defaultsTo(event, 'secondary_color', '00C9FF');
  const [moderators, setModerators] = useState(defaultsTo(event, 'moderators', []));
  const [userInfo, setUserInfo] = useState(event.client.voter_details ? JSON.parse(event.client.voter_details) : []);

  const domain = form.getFieldValue('domain_name') ?? event.domain_name;
  const displayHeader = form.getFieldValue('display_header') ?? event.display_header;
  const displayMenu = form.getFieldValue('display_menu') ?? event.display_menu;
  const displayQR = form.getFieldValue('display_qr') ?? event.display_qr;
  const paymentProvider = useMemo(() => {
    const value = form.getFieldValue('payment_providers');
    if (value !== undefined) {
      return value;
    }

    if (!event) return 'none';

    // todo: clean up different field name conventions
    const [provider] = event.paymentProviders || [];
    if (!provider) return 'none';

    return provider.reference;
  }, [form, event]);


  const [updateLeadGen] = useMutation(LEAD_GEN_UPDATE, {
    refetchQueries: ['clientList'],
  });

  const saveLeadGenDetails = async (id, payload) => {
    const unsafePattern = /["'\\;{}\x00\x08\x09\x1a\n\r\t\b\f]/g;
    const safePayload = payload.map((item) => {
      return !item ? '' : item.replace(unsafePattern, '')
    }).filter(str => str !== '');
    try {
      const result = await updateLeadGen({
        variables: {
          client_id: id,
          payload: {
            voter_details: JSON.stringify(safePayload),
          },
        },
      });
      if (result) {
        message.success('Successfully updated client');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  const handleAddUserInfo = useCallback(() => {
    const { value } = inputRef.current.input;
    if (userInfo.includes(value)) return message.error('Question already exists');
    if (userInfo.length >= 8) return message.error('Maximum amount of questions reached');
    if (value.length < 1) return message.error('Field cannot be empty');
    setUserInfo((previous) => [...previous, value]);
    inputRef.current.handleReset();
  });

  const handleRemoveUserInfo = useCallback((index) => {
    setUserInfo((previous) => {
      const next = [...previous];
      next.splice(index, 1);
      return next;
    });
  }, [setUserInfo]);

    const handleUserInfoBlur = useCallback((index, event) => {
      const { value } = event.target;
      setUserInfo((prevLabels) => {
        if(prevLabels[index] === value) return prevLabels;
        const nextLabels = [...prevLabels];
        nextLabels[index] = value;
        return nextLabels;
      });
    }, [setUserInfo]);

  const inputRef = useRef();

  useEffect(() => {
    saveLeadGenDetails(props.event.client.id, userInfo);
  }, [userInfo]);
  
  const onFormSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const payload = await validateFields();

      if (payload.display_menu !== false) {
        payload.display_menu = true;
      }

      if (payload.display_info !== true) {
        payload.display_info = false;
      }

      if (payload.primary_color && payload.primary_color.startsWith('#')) {
        payload.primary_color = payload.primary_color.substring(1);
      }
      if (payload.secondary_color && payload.secondary_color.startsWith('#')) {
        payload.secondary_color = payload.secondary_color.substring(1);
      }

      payload.display_qr = displayQR;

      onSubmit(payload);
      setFormChanged(false);
    } catch (err) {
      message.error('Form submission failed. Check below for details');
    }
  };
  const [autosaveProps, triggerAutosave, autosaveStatus] = useAutoSavingForm(onFormSubmit, {
    enabled: type === 'update',
    delay: 500,
  });
  const getDisplayUrl = () => {
    const eventDomain = getEventDomain(domain);
    if (eventDomain) {
      return eventDomain;
    }

    return `https://${domain}.votemo.eu`;
  };
  const canCopyLink = useMemo(
    () => !!navigator.clipboard,
    [],
  );
  const handleCopyLink = async () => {
    const url = getDisplayUrl();
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(url);
      message.success('Copied event link to clipboard');
    }
  };

  useEffect(() => {
    if (eventCreated) {
      message.success(`Successfully created event: ${event.name}`);
    }
    if (error) {
      message.error(error);
    }
  }, [eventCreated, error, event]);

  useEffect(() => {
    if (!event) return;
    if (!event.client) return;
    if (!event.client.moderators) return;

    const mappedModerators = event.client.moderators.map((cModerator) => {
      const mod = { ...cModerator };
      mod.selected = !!moderators.find((sModerator) => sModerator.id === cModerator.id);
      return mod;
    });
    setModerators(mappedModerators);
  }, [event]);

  useEffect(() => {
    if (clientModerators) {
      setModerators(clientModerators);
    }
  }, [clientModerators]);

  const [qrDataURL, setQrDataURL] = useState(null);
  useEffect(() => {
    QR.toDataURL(getDisplayUrl(), {
      color: { light: '#0000' },
      margin: 3,
    }, (err, url) => {
      if (err) {
        // eslint-disable-next-line
        console.error(err);
        return;
      }
      setQrDataURL(url);
    });
  });

  return (
    <div>
      <PageHeader className="page-header">
        <PageHeading title="event" type={type} name={event.name} isModerator={isModerator} />
        <AutosaveStatus status={autosaveStatus} />
        <PageControls
          type={type}
          onClickCreate={onFormSubmit}
          presenterUrl={presenterUrl}
        />
      </PageHeader>
      <Form
        className="event-form"
        layout="horizontal"
        {...autosaveProps}
      >
        <Row type="flex" gutter={16} style={{ marginBottom: 16 }}>
          <Col xs={24} sm={16} md={16}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={t('events.form.name.label')}
                style={{ margin: 0 }}
              >
                {getFieldDecorator('name', {
                  initialValue: event.name,
                  rules: [
                    { required: true, message: t('events.form.name.errors.required') },
                    { max: 200, message: t('events.form.name.errors.max') },
                  ],
                  onChange: triggerAutosave,
                })(<Input placeholder={t('events.form.name.placeholder')} size="small" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              {type === 'create' && (
                <StyledItem label={(
                  <>
                    <span style={{ marginRight: 8 }}>
                      {t('events.form.domain_name.label')}
                    </span>
                    <LocalizedTooltipBubble
                      contentKey="events.form.domain_name.tooltip"
                      placement="topLeft"
                      color="blue"
                    />
                  </>
                )}
                >
                  {getFieldDecorator('domain_name', {
                    initialValue: event.domain_name,
                    rules: [
                      { required: true, message: t('events.form.domain_name.errors.required') },
                      { max: 200, message: t('events.form.domain_name.errors.max') },
                      {
                        pattern: /^[a-z\d-]+$/i,
                        message: t('events.form.domain_name.errors.pattern'),
                      },
                    ],
                  })(<Input
                    addonAfter={<code>.{constants.domainBase}</code>}
                    placeholder={t('events.form.domain_name.placeholder')}
                    autoComplete="off"
                  />)}
                </StyledItem>
              )}
              {type === 'update' && (
                <StyledItem
                  label={(
                    <>
                      <span>{t('events.form.domain_name.label')}</span>
                      <LocalizedTooltipBubble color="blue" contentKey="events.form.domain_name.$update.tooltip" />
                    </>
                  )}
                  colon={false}
                  extra={canCopyLink && (
                    <BlockRight>
                      <CLinkButton type="button" onClick={handleCopyLink}>
                        {t('events.buttons.copy_link')}
                      </CLinkButton>
                    </BlockRight>
                  )}
                >
                  <Popover
                    content={(
                      <TooltipContentWrapper compact>
                        {t('events.form.domain_name.$update.tooltip')}
                      </TooltipContentWrapper>
                    )}
                    trigger="hover"
                    placement="bottomLeft"
                  >
                    <Input
                      value={getEventDomain(domain)}
                      style={{ letterSpacing: 1 }}
                      readOnly
                      disabled
                    />
                  </Popover>
                </StyledItem>
              )}
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label={t('events.form.description.label')}
                style={{ margin: 0 }}
              >
                <Popover
                  content={t('events.form.description.tooltip')}
                  placement="bottomLeft"
                  trigger="focus"
                >
                  {getFieldDecorator('description', {
                    initialValue: event.description || '',
                    rules: [
                      { max: 200, message: t('events.form.description.errors.max') },
                    ],
                    onChange: triggerAutosave,
                  })(
                    <Textarea
                      style={{ width: '100%', padding: 12, resize: 'none', lineHeight: 1.7 }}
                      minRows={3}
                      maxRows={5}
                      placeholder={t('events.form.description.placeholder')}
                    />,
                  )}
                </Popover>
              </Form.Item>
            </Col>
          </Col>
          <Col xs={24} sm={8}>
            <Row type="flex" justify="end">
              <QRCode data={getDisplayUrl()} options={{ width: 132, margin: 0, padding: 0 }} />
            </Row>
            {qrDataURL && (
              <Row type="flex" justify="end">
                <CLink download={`qr_${event.domain_name}.png`} href={qrDataURL} target="_blank">
                  Download Event QR
                </CLink>
              </Row>
            )}
          </Col>
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={24} md={{ span: 12, order: 0 }} style={{ marginBottom: 16 }}>
            <Card>
              <Col span={24}>
                <StyledItem>
                  {getFieldDecorator('display_header', {
                    valuePropName: 'checked',
                    initialValue: event.display_header || false,
                    onChange: triggerAutosave,
                  })(<Switch onChange={triggerAutosave} />)}
                  <span style={{ margin: '0 8px 0 4px' }}>
                    {t('events.form.display_header.label')}
                  </span>
                </StyledItem>
              </Col>
              <Col span={24} hidden={!displayHeader}>
                <Form.Item>
                  {getFieldDecorator('display_menu', {
                    valuePropName: 'checked',
                    initialValue: event.display_menu || false,
                    onChange: triggerAutosave,
                  })(
                    <Switch />,
                  )}
                  <span style={{ margin: '0 8px 0 4px' }}>
                    {t('events.form.display_menu.label')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={24} hidden={!(displayHeader && displayMenu)}>
                <Form.Item>
                  {getFieldDecorator('display_info', {
                    valuePropName: 'checked',
                    initialValue: event.display_info || false,
                    onChange: triggerAutosave,
                  })(
                    <Switch />,
                  )}
                  <span style={{ margin: '0 8px 0 4px' }}>
                    {t('events.form.display_info.label')}
                  </span>
                  <LocalizedTooltipBubble color="blue" contentKey="events.form.display_info.tooltip" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('display_qr', {
                    valuePropName: 'checked',
                    initialValue: event.display_qr || false,
                    onChange: triggerAutosave,
                  })(
                    <Switch />,
                  )}
                  <span style={{ margin: '0 8px 0 4px' }}>
                    {t('events.form.display_qr.label')}
                  </span>
                  <LocalizedTooltipBubble color="blue" contentKey="events.form.display_qr.tooltip" />
                </Form.Item>
              </Col>
            </Card>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: 16 }}>
            <Collapse>
              <Collapse.Panel key="branding" header="Branding">
                <Row align="top" type="flex">
                  <Col span={8}>
                    <ImageUploadButton
                      value={event.image}
                      title={t('events.form.logo.label')}
                      information={t('events.form.logo.tooltip')}
                      onChange={(filename) => {
                        setFieldsValue({ image: filename });
                        triggerAutosave();
                      }}
                    />
                    {getFieldDecorator('image', { initialValue: event.image })(
                      <Input type="hidden" />,
                    )}
                  </Col>
                  <Col span={16}>
                    <Popover
                      content={(
                        <TooltipContentWrapper compact>
                          {t('events.form.primary_color.tooltip')}
                        </TooltipContentWrapper>
                      )}
                      placement="topLeft"
                      trigger="hover"
                    >
                      <label htmlFor="color.primary">
                        {getFieldDecorator('primary_color', {
                          initialValue: `#${primaryColor}`,
                          onChange: triggerAutosave,
                        })(
                          <input id="color.primary" type="color" />,
                        )}
                        <span style={{ marginLeft: 12 }}>Primary color</span>
                      </label>
                    </Popover>
                    <Popover
                      content={(
                        <TooltipContentWrapper compact>
                          {t('events.form.secondary_color.tooltip')}
                        </TooltipContentWrapper>
                      )}
                      placement="topLeft"
                      trigger="hover"
                    >
                      <label htmlFor="color.secondary">
                        {getFieldDecorator('secondary_color', {
                          initialValue: `#${secondaryColor}`,
                          onChange: triggerAutosave,
                        })(
                          <input id="color.secondary" type="color" />,
                        )}
                        <span style={{ marginLeft: 12 }}>Secondary color</span>
                      </label>
                    </Popover>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel key="advanced" header="Advanced">
                <Row type="flex" gutter={16}>
                  <Form.Item>
                    {getFieldDecorator('track_user', {
                      valuePropName: 'checked',
                      initialValue: event.track_user || false,
                      onChange: triggerAutosave,
                    })(<Switch onChange={triggerAutosave} />)}
                    <span style={{ margin: '0 8px 0 4px' }}>
                      {t('events.form.lead_tracking.label')}
                    </span>
                    <LocalizedTooltipBubble color="blue" contentKey="events.form.lead_tracking.tooltip" />
                  </Form.Item>
                </Row>
                <Row type="flex">
                  <Col span={14}>
                    <Form.Item label={t('events.form.moderators.label')}>
                      <Popover
                        content={(
                          <TooltipContentWrapper compact>
                            {t('events.form.moderators.tooltip')}
                          </TooltipContentWrapper>
                        )}
                        placement="topLeft"
                        trigger="hover"
                      >
                        {getFieldDecorator('moderators', {
                          initialValue: event.moderators?.map((moderator) => moderator.id) || [],
                          onChange: triggerAutosave,
                        })(
                          <Select
                            placeholder={t('events.form.moderators.placeholder')}
                            mode="multiple"
                          >
                            {moderators.map((moderator) => (
                              <Option
                                value={moderator.id}
                                key={moderator.id}
                              >
                                {moderator.first_name} {moderator.last_name}
                              </Option>
                            ))}
                          </Select>,
                        )}
                      </Popover>
                    </Form.Item>
                  </Col>
                  <Col push={1} span={8}>
                    <Form.Item label={t('events.form.language.label')}>
                      <Popover
                        content={(
                          <TooltipContentWrapper compact>
                            {t('events.form.language.tooltip')}
                          </TooltipContentWrapper>
                        )}
                        placement="topRight"
                        trigger="hover"
                      >
                        {getFieldDecorator('language', {
                          rules: [{ required: true, message: t('events.form.language.errors.required') }],
                          initialValue: event.language || 'EN',
                          onChange: triggerAutosave,
                        })(
                          <Select placeholder={t('events.form.language.errors.required')}>
                            {['EN', 'ET', 'LV', 'CA'].map((language) => (
                              <Option key={language} value={language}>
                                {t(`events.form.language.values.${language}`)}
                              </Option>
                            ))}
                          </Select>,
                        )}
                      </Popover>
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={14}>
                    <Form.Item label={t('events.form.payment_provider.label')}>
                      <Popover
                        content={t('events.form.payment_provider.tooltip')}
                        placement="bottomLeft"
                        trigger="hover"
                      >
                        {getFieldDecorator('payment_provider', {
                          rules: [{ required: true, message: t('events.form.payment_provider.errors.required') }],
                          initialValue: paymentProvider,
                          onChange: triggerAutosave,
                        })(
                          <Select placeholder={t('events.form.payment_provider.placeholder')}>
                            <Option value="none">None</Option>
                            <Option value="mobi">Mobi</Option>
                            <Option value="zlick">Zlick</Option>
                          </Select>,
                        )}
                      </Popover>
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel key="Lead gen" header="Lead generation">
                <Row type="flex">
                  <Col span={14}>
                    <Label>{t('modules.form.lead_gen.label')}</Label>
                    <Input
                      placeholder={t('modules.form.lead_gen.placeholder')}
                      ref={inputRef}
                      addonAfter={(
                        <AddonButton size="small" onClick={handleAddUserInfo}>
                          <Icon type="plus" />
                        </AddonButton>
                      )}
                    />
                    <RightSpan>Current fields:</RightSpan>
                    <Input
                        disabled={true}
                        defaultValue={'name'}
                        placeholder={'name'}
                      />
                      <Input
                        disabled={true}
                        defaultValue={'email'}
                        placeholder={'email'}
                      />
                    {[...userInfo].map((label, index) => (
                      <Input
                        key={label || `label-empty-${index}`}
                        defaultValue={label}
                        placeholder={t('modules.form.moodmeter_labels.placeholder')}
                        onBlur={(e) => handleUserInfoBlur(index, e)}
                        addonAfter={(
                          <AddonButton type="danger" size="small" onClick={() => handleRemoveUserInfo(index)}>
                            <Icon type="delete" />
                          </AddonButton>
                        )}
                      />
                  ))}
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(EventForm);
